import React from "react"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import JumboPhoto from "../../components/jumbophoto"
import ginaPhoto from "../../images/gina-mcdonald.jpg"
import heatherPhoto from "../../images/rsz_1heather-bellew.png"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

export default () => <Layout>
	<Helmet title="Attorneys at Gina Mcdonald &amp; Associates, L.L.C." defer={false} />

	<JumboPhoto/>

	<Container>
        <Row>
            <Col>
<h1>Attorneys</h1>
            <p>We are on your side, ready to listen to your concerns and advise you of all of your options. Learn more about the background and qualifications of our attorneys representing clients throughout central Alabama. Contact us today for a free bankruptcy consultation. We have offices in Birmingham, Jasper and Oneonta. Call today, 205-982-3325.</p>
            </Col>
        </Row>
		<Row>
			<Col>
            <img className="img-fluid mb-3" src={ginaPhoto} alt="Gina McDonald" />
            <h1>Gina H. McDonald<br/><small className="text-muted">Managing Partner</small></h1>
            <Link to="/attorneys/gina-mcdonald" className="stretched-link" />
            </Col>
			<Col>
            <img className="img-fluid mb-3" src={heatherPhoto} alt="Heather Bellew" />
				<h1>Heather M. Bellew<br/><small className="text-muted">Associate</small></h1>
            <Link to="/attorneys/heather-bellew" className="stretched-link" />
            </Col>
		</Row>
	</Container>
</Layout>